import React, { useState } from 'react';

import {
  ACTION_ERROR_INTER,
  FORM_VALUE_ENUM,
  FORM_VALUE_INTER,
  FORM_VALUE_RAW,
  FORM_VALUE_TYPE,
} from '../event-item-create/constant';

import { Component } from './component';
import {
  lessThanTime,
  maxLength,
  moreThanTime,
  required,
  requiredArrayIfEqual,
  requiredIfEqual,
} from '../../lib/validation/service';
import { FormikValues, getIn, useFormik } from 'formik';
import { validation } from '../../lib/validation';
import { useMutation, useQueryClient } from 'react-query';
import { updateProject } from './action';
import { EVENT_ITEM_DATA, EVENT_TYPE } from '../../data/event/constant';
import { EVENT_LIST_MODULE_NAME } from '../event-list';
import { SELECT_OPTION_ITEM_DATA } from '../../common/select';
import { convert } from '../event-item-create/convert';
import { i18n } from '../../lib/lang';
import * as Yup from 'yup';

export const Container: React.FC<{
  data: EVENT_ITEM_DATA;
  sessionId: string;
  close: () => void;
  sessionAuto: boolean;
}> = ({ data, close, sessionId, sessionAuto }) => {
  const query = useQueryClient();
  const onSuccess = (d: any, values: any) => {
    close();
    formik.resetForm();
    query.invalidateQueries([EVENT_LIST_MODULE_NAME, sessionId]);
  };

  const action = useMutation(
    (values: FORM_VALUE_INTER) => updateProject(data.id, values),
    { onSuccess },
  );

  const config = {
    [FORM_VALUE_ENUM.TYPE]: [required],
    [FORM_VALUE_ENUM.MESSAGE]: [
      requiredIfEqual(FORM_VALUE_ENUM.TYPE, EVENT_TYPE.MESSAGE),
      requiredIfEqual(FORM_VALUE_ENUM.TYPE, EVENT_TYPE.GROUP_MESSAGE),
      requiredIfEqual(FORM_VALUE_ENUM.TYPE, EVENT_TYPE.SURVEY),
      maxLength(320),
    ],
    [FORM_VALUE_ENUM.TIME]: sessionAuto
      ? [required, lessThanTime(FORM_VALUE_ENUM.END_TIME)]
      : [
          requiredIfEqual(FORM_VALUE_ENUM.TYPE, EVENT_TYPE.TIMECODE),

          lessThanTime(FORM_VALUE_ENUM.END_TIME),
          lessThanTime(FORM_VALUE_ENUM.TIME_BOOST),
        ],
    [FORM_VALUE_ENUM.PERSON]: [
      requiredArrayIfEqual(FORM_VALUE_ENUM.TYPE, EVENT_TYPE.MESSAGE),
      requiredArrayIfEqual(FORM_VALUE_ENUM.TYPE, EVENT_TYPE.GROUP_MESSAGE),
      requiredArrayIfEqual(
        FORM_VALUE_ENUM.TYPE,
        EVENT_TYPE.RESERVATION_CREATED,
      ),
      requiredArrayIfEqual(FORM_VALUE_ENUM.TYPE, EVENT_TYPE.QUESTION),
    ],
    [FORM_VALUE_ENUM.BUTTON_TEXT]: [
      requiredIfEqual(FORM_VALUE_ENUM.TYPE, EVENT_TYPE.BUTTON),
      requiredIfEqual(FORM_VALUE_ENUM.TYPE, EVENT_TYPE.RESERVATION),
    ],

    [FORM_VALUE_ENUM.TIME_BOOST]: sessionAuto
      ? [
          requiredIfEqual(FORM_VALUE_ENUM.TYPE, EVENT_TYPE.RESERVATION),
          requiredIfEqual(FORM_VALUE_ENUM.TYPE, EVENT_TYPE.SURVEY),
          lessThanTime(FORM_VALUE_ENUM.END_TIME),
          moreThanTime(FORM_VALUE_ENUM.TIME),
        ]
      : [],
    [FORM_VALUE_ENUM.PLACE_AVAILABLE]: [
      requiredIfEqual(FORM_VALUE_ENUM.TYPE, EVENT_TYPE.RESERVATION),
    ],
    [FORM_VALUE_ENUM.PLACE_RESERVED]: [
      requiredIfEqual(FORM_VALUE_ENUM.TYPE, EVENT_TYPE.RESERVATION),
    ],
    [FORM_VALUE_ENUM.FILE]: [
      requiredIfEqual(FORM_VALUE_ENUM.TYPE, EVENT_TYPE.BANNER),
    ],
    [FORM_VALUE_ENUM.LINK]: [
      requiredIfEqual(FORM_VALUE_ENUM.TYPE, EVENT_TYPE.BANNER),
      requiredIfEqual(FORM_VALUE_ENUM.TYPE, EVENT_TYPE.BUTTON),
    ],
    [FORM_VALUE_ENUM.END_TIME]: sessionAuto
      ? [
          requiredIfEqual(FORM_VALUE_ENUM.TYPE, EVENT_TYPE.BANNER),
          requiredIfEqual(FORM_VALUE_ENUM.TYPE, EVENT_TYPE.REACTION),
          requiredIfEqual(FORM_VALUE_ENUM.TYPE, EVENT_TYPE.SURVEY),
          requiredIfEqual(FORM_VALUE_ENUM.TYPE, EVENT_TYPE.BUTTON),
          requiredIfEqual(FORM_VALUE_ENUM.TYPE, EVENT_TYPE.QUESTION),
          requiredIfEqual(FORM_VALUE_ENUM.TYPE, EVENT_TYPE.GROUP_MESSAGE),
          moreThanTime(FORM_VALUE_ENUM.TIME),
          moreThanTime(FORM_VALUE_ENUM.TIME_BOOST),
        ]
      : [],
    [FORM_VALUE_ENUM.BUTTON_COLOR]: [
      requiredIfEqual(FORM_VALUE_ENUM.TYPE, EVENT_TYPE.BUTTON),
    ],
    [FORM_VALUE_ENUM.TEXT_COLOR]: [
      requiredIfEqual(FORM_VALUE_ENUM.TYPE, EVENT_TYPE.BUTTON),
    ],
  };

  const validate = (values: FormikValues) => validation(values, config);

  const validationSchema = Yup.object().shape({
    reaction: Yup.array()
      .of(
        Yup.object().shape({
          emoji: Yup.string().required(i18n.t('VALIDATION.REQUIRED')),
          quantity: sessionAuto
            ? Yup.string().required(i18n.t('VALIDATION.REQUIRED'))
            : Yup.string().notRequired(),
        }),
      )
      .when('type', {
        is: (type: any) => type === 'REACTION',
        then: (schema) => schema.required(i18n.t('VALIDATION.REQUIRED')),
        otherwise: (schema) => schema.strip(),
      }),

    answers: Yup.array()
      .of(
        Yup.object().shape({
          text: Yup.string().required(i18n.t('VALIDATION.REQUIRED')),
          quantity: sessionAuto
            ? Yup.string().required(i18n.t('VALIDATION.REQUIRED'))
            : Yup.string().notRequired(),
        }),
      )
      .when('type', {
        is: (type: any) => type === 'SURVEY',
        then: (schema) => schema.required(i18n.t('VALIDATION.REQUIRED')),
        otherwise: (schema) => schema.strip(),
      }),
  });

  const initialValues = {
    [FORM_VALUE_ENUM.MESSAGE]: data.message,
    [FORM_VALUE_ENUM.MESSAGES]: data?.messages,
    [FORM_VALUE_ENUM.BUTTON_TEXT]: data.buttonText,
    [FORM_VALUE_ENUM.TIME]: data.time,
    [FORM_VALUE_ENUM.TIME_BOOST]: data.timeBoost,
    [FORM_VALUE_ENUM.END_TIME]: data.endTime,
    [FORM_VALUE_ENUM.TYPE]: data.type,
    [FORM_VALUE_ENUM.PERSON]: data?.person?.id,
    [FORM_VALUE_ENUM.PLACE_AVAILABLE]: data?.placeAvailable,
    [FORM_VALUE_ENUM.PLACE_RESERVED]: data?.placeReserved,
    [FORM_VALUE_ENUM.LINK]: data?.link,
    [FORM_VALUE_ENUM.BUTTON_COLOR]: data?.buttonColor,
    [FORM_VALUE_ENUM.TEXT_COLOR]: data?.textColor,
    [FORM_VALUE_ENUM.FILE]: data?.file?.id,
    [FORM_VALUE_ENUM.MESSAGE_REPLY]: data?.messageReply?.id,
    [FORM_VALUE_ENUM.REACTION]: data?.reaction,
    [FORM_VALUE_ENUM.ANSWER]: data?.answers,
  };

  const formik: FormikValues = useFormik({
    initialValues,
    validationSchema,
    validate,
    enableReinitialize: true,
    onSubmit: (values: FORM_VALUE_RAW) => {
      return action.mutate(convert(values));
    },
  });

  const isFieldError = (name: FORM_VALUE_TYPE): boolean => {
    return formik.errors[name] && formik.touched[name] ? true : false;
  };

  const getFieldError = (name: FORM_VALUE_TYPE): string | undefined =>
    isFieldError(name) ? formik.errors[name] : undefined;

  const isSubmitDisabled = () => {
    if (!formik.isValid) {
      return true;
    }

    if (action.isLoading) {
      return true;
    }

    if (action.isSuccess) {
      return true;
    }
  };

  const isLoading = () => {
    if (action.isLoading) {
      return true;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;
    if (error) {
      return error.message;
    }
  };

  const getFieldValue = (name: FORM_VALUE_TYPE) => formik.values[name];

  const setFieldValue = (name: FORM_VALUE_TYPE, e: SELECT_OPTION_ITEM_DATA) => {
    formik.setFieldValue(name, Array.isArray(e) ? e : e.value);
  };

  const onSuccessUpload = (id: string, name: string) => {
    formik.setFieldValue(name, id);
  };

  const getSelectValue = (options: SELECT_OPTION_ITEM_DATA[], name: any) => {
    return (
      options?.filter(
        (item: SELECT_OPTION_ITEM_DATA) =>
          item.value == getIn(formik.values, name),
      )[0] || null
    );
  };

  return (
    <Component
      isFieldError={isFieldError}
      getFieldError={getFieldError}
      isSubmitDisabled={isSubmitDisabled}
      getFieldValue={getFieldValue}
      formik={formik}
      isError={isError()}
      isLoading={isLoading()}
      errorMessage={getErrorMessage()}
      close={close}
      sessionId={sessionId}
      setFieldValue={setFieldValue}
      file={data?.file?.url || data?.fileUrl}
      onSuccessUpload={onSuccessUpload}
      getSelectValue={getSelectValue}
    />
  );
};
