import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import 'plyr/dist/plyr.css';

import ReactPlayer from 'react-player/youtube';

export const Component: React.FC<{
  src?: string;
}> = ({ src }) => {
  const playerRef = useRef(null);

  return (
    <PlayerWrapper>
      <Player
        url={src}
        playing={true}
        controls={false} // Отключение стандартных элементов управления ReactPlayer
        config={{
          //@ts-ignore

          youtube: {
            playerVars: {
              controls: 0, // Включение стандартных элементов управления
              autoplay: 1,
              cc_load_policy: 0,
              disablekb: 1,
              iv_load_policy: 3,
              modestbranding: 1,
              showinfo: 0,
            },
          },
        }}
      />
    </PlayerWrapper>
  );
};

const Player = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .ytp-show-cards-title {
    display: none;
  }
  .ytp-watermark {
    display: none;
  }
`;

const PlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25%; /* Соотношение 16:9 */
  height: 0;
  overflow: hidden;

  > {
    width: 100%;
    height: 100%;
  }
  /* Для обеспечения адаптивности */
  div,
  iframe,
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
`;
