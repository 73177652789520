import React from 'react';

import { FormikValues } from 'formik';

import { FORM_VALUE_ENUM } from './constant';

import { GridElem } from '../../common/grid';
import { ButtonElem } from '../../common/button';
import { LoaderElem } from '../../common/loader';
import { AlertActionElem } from '../../common/alert-action';
import { FormElem } from '../../common/form';
import { i18n } from '../../lib/lang';
import { SelectPreloadContainer } from '../select-preload';
import { ContentContainerElem } from '../../common/content-container';
import { DoubleContainerElem } from '../../common/double-container';
import importIcon from '../../asset/svg/button/import.svg';
import importIconLight from '../../asset/svg/button/import-light-theme.svg';

import { getWebinarList } from '../../data/webinar/action';
import { convertWebinarListToSelect } from '../../data/webinar/convert';
import { CheckBoxElem } from '../../common/checkbox';
import { TextElem } from '../../common/text';
import { convertSessionListToSelect } from '../../data/session/convert';
import { getSessionList } from '../../data/session/action';
import { THEME_MODULE_NAME } from '../../data/theme';
import { useSelector } from '../../lib/store';
import { THEME_ENUM } from '../../data/theme/constant';
import { SelectDynamicContainer } from '../select-dynamic';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  setFieldValue: Function;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  isSubmitDisabled,
  getFieldValue,
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  setFieldValue,
}) => {
  const { theme } = useSelector((s: any) => ({
    theme: s[THEME_MODULE_NAME],
  }));
  console.log(getFieldValue(FORM_VALUE_ENUM.WEBINAR));
  return (
    <FormElem onSubmit={formik.handleSubmit}>
      {isLoading && <LoaderElem />}

      <GridElem spacing={5}>
        <ContentContainerElem>
          <GridElem spacing={5}>
            <TextElem tid="WEBINAR.IMPORT.TITLE" type="semi-bold" size="main" />
            <SelectDynamicContainer
              name={FORM_VALUE_ENUM.WEBINAR}
              action={getWebinarList}
              convert={convertWebinarListToSelect}
              formik={formik}
              errorMessage={getFieldError(FORM_VALUE_ENUM.WEBINAR)}
              error={isFieldError(FORM_VALUE_ENUM.WEBINAR)}
              value={getFieldValue(FORM_VALUE_ENUM.WEBINAR)}
              placeholder="Choose webinar name"
              dynamic
            />
          </GridElem>
          <GridElem spacing={4}>
            <CheckBoxElem
              name={FORM_VALUE_ENUM.SPEAKER}
              checked={getFieldValue(FORM_VALUE_ENUM.SPEAKER)}
              onChange={formik.handleChange}
              tid="WEBINAR.IMPORT.FORM.SPEAKER"
            />
            <CheckBoxElem
              name={FORM_VALUE_ENUM.LOGO}
              checked={getFieldValue(FORM_VALUE_ENUM.LOGO)}
              onChange={formik.handleChange}
              tid="WEBINAR.IMPORT.FORM.LOGO"
            />
            <CheckBoxElem
              name={FORM_VALUE_ENUM.SPEAKER_IMG}
              checked={getFieldValue(FORM_VALUE_ENUM.SPEAKER_IMG)}
              onChange={formik.handleChange}
              tid="WEBINAR.IMPORT.FORM.SPEAKER_IMG"
            />
            <CheckBoxElem
              name={FORM_VALUE_ENUM.TOPICS}
              checked={getFieldValue(FORM_VALUE_ENUM.TOPICS)}
              onChange={formik.handleChange}
              tid="WEBINAR.IMPORT.FORM.TOPICS"
            />
            <CheckBoxElem
              name={FORM_VALUE_ENUM.SESSION}
              checked={getFieldValue(FORM_VALUE_ENUM.SESSION)}
              onChange={formik.handleChange}
              tid="WEBINAR.IMPORT.FORM.SESSION"
            />
            <CheckBoxElem
              name={FORM_VALUE_ENUM.WEBGIFT}
              checked={getFieldValue(FORM_VALUE_ENUM.WEBGIFT)}
              onChange={formik.handleChange}
              tid="WEBINAR.IMPORT.FORM.WEBGIFT"
            />
            <CheckBoxElem
              name={FORM_VALUE_ENUM.DESCRIPTION}
              checked={getFieldValue(FORM_VALUE_ENUM.DESCRIPTION)}
              onChange={formik.handleChange}
              tid="WEBINAR.IMPORT.FORM.DESCRIPTION"
            />
            <CheckBoxElem
              name={FORM_VALUE_ENUM.BUTTON_LINK}
              checked={getFieldValue(FORM_VALUE_ENUM.BUTTON_LINK)}
              onChange={formik.handleChange}
              tid="WEBINAR.IMPORT.FORM.BUTTON_LINK"
            />
            <CheckBoxElem
              name={FORM_VALUE_ENUM.PERSON}
              checked={
                getFieldValue(FORM_VALUE_ENUM.EVENT) ||
                getFieldValue(FORM_VALUE_ENUM.PERSON)
              }
              onChange={formik.handleChange}
              tid="WEBINAR.IMPORT.FORM.PERSON"
              disabled={getFieldValue(FORM_VALUE_ENUM.EVENT)}
            />
            <CheckBoxElem
              name={FORM_VALUE_ENUM.EVENT}
              checked={getFieldValue(FORM_VALUE_ENUM.EVENT)}
              onChange={formik.handleChange}
              tid="WEBINAR.IMPORT.FORM.EVENT"
            />
            {(getFieldValue(FORM_VALUE_ENUM.EVENT) ||
              getFieldValue(FORM_VALUE_ENUM.PERSON)) && (
              <SelectPreloadContainer
                name={FORM_VALUE_ENUM.SESSION_PARENT}
                action={getSessionList}
                convert={convertSessionListToSelect}
                formik={formik}
                onChange={setFieldValue}
                errorMessage={getFieldError(FORM_VALUE_ENUM.SESSION_PARENT)}
                error={isFieldError(FORM_VALUE_ENUM.SESSION_PARENT)}
                value={getFieldValue(FORM_VALUE_ENUM.SESSION_PARENT)}
                placeholder="Choose session name"
                dynamic
                isDisabled={!getFieldValue(FORM_VALUE_ENUM.WEBINAR)}
                param={getFieldValue(FORM_VALUE_ENUM.WEBINAR)}
              />
            )}
          </GridElem>
        </ContentContainerElem>

        <DoubleContainerElem>
          <ButtonElem
            tid="WEBINAR.IMPORT.BUTTON.IMPORT"
            disabled={isSubmitDisabled()}
            iconRight={
              theme.type === THEME_ENUM.LIGHT ? importIconLight : importIcon
            }
          />
        </DoubleContainerElem>

        {isError && <AlertActionElem text={i18n.t(`${errorMessage}`)} />}
        {isSuccess && <AlertActionElem type="success" tid="Success" />}
      </GridElem>
    </FormElem>
  );
};
