import React, { useEffect, useState } from 'react';
import { i18n } from '../../lib/lang';
import { PROPS_TYPE } from './constant';
import { SelectAsyncElem } from '../../common/select-async';
import { SELECT_OPTION_ITEM_DATA } from '../../common/select';
import { getIn } from 'formik';
import styled from 'styled-components';

export const Container: React.FC<PROPS_TYPE> = ({
  action,
  convert,
  formik,
  param,
  value,
  isMulti,
  name,
  ...props
}) => {
  const [selectedOption, setSelectedOption] = useState<
    SELECT_OPTION_ITEM_DATA[] | null
  >(isMulti ? [] : null);
  const [initialOptions, setInitialOptions] = useState<
    SELECT_OPTION_ITEM_DATA[]
  >([]);

  // Функция для загрузки опций
  const loadOptions = async (inputValue: string) => {
    return convert(await action(param, inputValue));
  };

  useEffect(() => {
    if (!props.isDisabled) {
      const loadInitialOptions = async () => {
        try {
          const initialData = await action(param, '');
          const convertedData = convert(initialData);
          setInitialOptions(convertedData);
        } catch (e) {
          console.log(e);
        }
      };

      loadInitialOptions();
    }
  }, [action, param, convert]);

  useEffect(() => {
    if (!props?.isDisabled) {
      if (value?.length) {
        const loadSelectedOption = async () => {
          try {
            if (getIn(formik.values, name)) {
              const selectedId = getIn(formik.values, name);
              const result = convert(await action(param, selectedId));
              console.log('loadSelectedOption', result, value);
              setSelectedOption(result || null);
            }
          } catch (e) {
            console.log(e);
          }
        };

        loadSelectedOption();
      } else {
        setSelectedOption(null);
      }
    }
  }, [name]);

  const onChange = (name: string, event: any) => {
    if (isMulti) {
      formik.setFieldValue(
        name,
        Array.isArray(event)
          ? event.map((item: SELECT_OPTION_ITEM_DATA) => item.value)
          : [event.value],
      );
      setSelectedOption(Array.isArray(event) ? event : []);
    } else {
      formik.setFieldValue(name, event?.value || null);

      setSelectedOption(event);
    }
  };

  const multiValueContainer = ({ selectProps, data }: any) => {
    const label = data.label;
    const allSelected = selectProps.value;
    if (allSelected[allSelected.length - 1].label === label) {
      const val = allSelected.map((selected: any) => selected.label).join(', ');
      return <MultiLabel>{val}</MultiLabel>;
    } else {
      return null;
    }
  };

  const customComponents = isMulti
    ? { MultiValueContainer: multiValueContainer }
    : {};

  const onInputChange = (e: any) => {
    console.log('onInputChange', e);
  };
  console.log(selectedOption);

  return (
    <SelectAsyncElem
      cacheOptions
      defaultOptions={initialOptions}
      loadOptions={loadOptions}
      loadingMessage={() => i18n.t('COMMON.SELECT.LOADING')}
      dynamic
      onChange={onChange}
      onInputChange={onInputChange}
      value={selectedOption}
      closeMenuOnSelect={!isMulti}
      customComponents={customComponents}
      isMulti={isMulti}
      name={name}
      {...props}
    />
  );
};

const MultiLabel = styled.p`
  white-space: nowrap;
  overflow: hidden;
  display: initial;
  text-overflow: ellipsis;
  width: 100%;
  position: absolute;
`;
