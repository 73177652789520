import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import { SkeletonFieldElem } from '../../common/skeleton-field';
import { SESSION_ITEM_DATA } from '../../data/session/constant';
import { BASE_URL } from '../../lib/http/constant';
import { SessionItemRoomLiveVideoContainer } from '../session-item-room-live-video';
import { VideoPlayerElem } from '../../common/video';

export const Component: React.FC<{
  data?: SESSION_ITEM_DATA;
  isLoading?: boolean;
  sessionStart: boolean;
  isSuccess?: boolean;
}> = ({ isLoading, isSuccess, data, sessionStart }) => {
  const [src, setSrc] = useState('');

  useMemo(() => {
    if (data?.id) {
      const uniqueParam = new Date().getTime();
      let videoSrc = '';

      if (sessionStart) {
        if (data.auto) {
          videoSrc = `${BASE_URL}/session/videoStream/${data?.id}?t=${uniqueParam}`;
        } else {
          videoSrc = data.webinar?.youtubeLink || '';
        }
      } else {
        videoSrc = `${BASE_URL}/session/previewStream/${data?.id}`;
      }

      setSrc(`${videoSrc}?_=${uniqueParam}`);
    }
  }, [data?.id, sessionStart]);

  return (
    <div style={{ height: 'auto', width: '100%' }}>
      {isSuccess && !!src && (
        <>
          {data?.sessionStarted && !data.auto ? (
            <SessionItemRoomLiveVideoContainer src={src} />
          ) : (
            // <VideoPlayerElem src={src} />
            <VideoPlayerElem
              autoPlay
              muted={true}
              loop={true}
              src={src}
              playControl={false}
              progressControl={false}
            />
          )}
        </>
      )}

      {isLoading && !isSuccess && !src && (
        <SkeletonField
          title={false}
          style={{ width: '100%', height: '100%' }}
        />
      )}
    </div>
  );
};
const SkeletonField = styled(SkeletonFieldElem)`
  .field {
    height: 100%;
    width: 100%;
    min-height: 390px;
  }
`;
