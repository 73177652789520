import React from 'react';
import { EVENT_ITEM_DATA, EVENT_TYPE } from '../constant';
import { Spacing } from '../../../theme';
import { TextElem } from '../../../common/text';
import { EllipsisContainerElem } from '../../../common/ellipsis-container';
import styled from 'styled-components';

export const Banner: React.FC<{
  data: EVENT_ITEM_DATA;
}> = ({ data }) => {
  const { link, file, fileUrl } = data;

  return (
    <>
      <EllipsisContainerElem>
        <TextElem
          type="medium"
          color="textFourth"
          oneLine
          style={{ paddingLeft: `${Spacing(5)}` }}
        >
          {link}
        </TextElem>
      </EllipsisContainerElem>
      <Image src={file?.url || fileUrl} />
    </>
  );
};

const Image = styled.img`
  height: 40px;
  border-radius: 8px;
  margin-left: ${Spacing(5)};
`;
