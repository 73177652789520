import React, { useState, useRef, useCallback, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { FlexElem } from '../../common/flex';
import { ReactComponent as PlayIcon } from '../../asset/svg/video/play.svg';
import { ReactComponent as PauseIcon } from '../../asset/svg/video/pause.svg';
import { ReactComponent as MuteIcon } from '../../asset/svg/video/volume.svg';
import { ReactComponent as UnmuteIcon } from '../../asset/svg/video/muted.svg';
import { ReactComponent as ExitFullScreenIcon } from '../../asset/svg/video/exit-fullscreen.svg';
import { ReactComponent as EnterFullScreenIcon } from '../../asset/svg/video/enter-fullscreen.svg';
import styled from 'styled-components';
import { ButtonElem } from '../../common/button';
import { LoaderIconElem } from '../loader-icon';

export const Elem: React.FC<{
  src: string;
  playControl?: boolean;
  volumeControl?: boolean;
  fullScreenControl?: boolean;
  progressControl?: boolean;
  autoPlay?: boolean;
  muted?: boolean;
  loop?: boolean;
  startTime?: number;
}> = ({
  src,
  playControl = true,
  volumeControl = true,
  fullScreenControl = true,
  progressControl = true,
  autoPlay = false,
  muted: initialMuted = false,
  loop = false,
  startTime = 0,
}) => {
  const playerRef = useRef<ReactPlayer>(null);
  const [playing, setPlaying] = useState(autoPlay);
  const [volume, setVolume] = useState(1);
  const [muted, setMuted] = useState(initialMuted);
  const [played, setPlayed] = useState(0);
  const [duration, setDuration] = useState(0);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const handleReady = () => {
    setIsLoading(false); // Видео готово
  };
  const [url, setUrl] = useState(src);

  const handlePlayPause = useCallback(() => {
    setPlaying((prev) => !prev);
  }, []);

  const toggleMute = () => {
    setMuted((prev) => !prev);
  };

  const seekForward = () => {
    if (playerRef.current) {
      playerRef.current.seekTo(playerRef.current.getCurrentTime() + 10);
    }
  };

  const seekBackward = () => {
    if (playerRef.current) {
      playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10);
    }
  };

  const [isFullscreen, setIsFullscreen] = useState(false);
  const videoRef = useRef<HTMLDivElement | null>(null);

  const toggleFullscreen = () => {
    if (!isFullscreen && videoRef?.current) {
      videoRef.current.requestFullscreen().then(() => {
        // Установить альбомную ориентацию
        if (screen.orientation && 'lock' in screen.orientation) {
          (screen.orientation as any).lock('landscape').catch((err: Error) => {
            console.error('Не удалось установить альбомную ориентацию:', err);
          });
        }
      });
    } else {
      document.exitFullscreen().then(() => {
        // Вернуть ориентацию в портретный режим
        if (screen.orientation && 'unlock' in screen.orientation) {
          (screen.orientation as any).unlock();
        }
      });
    }
    setIsFullscreen((prev) => !prev);
  };

  const formatTime = (seconds: number) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);

    if (hrs > 0) {
      return `${String(hrs).padStart(2, '0')}:${String(mins).padStart(
        2,
        '0',
      )}:${String(secs).padStart(2, '0')}`;
    }
    return `${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };

  useEffect(() => {
    if (playerRef.current && startTime > 0) {
      playerRef.current.seekTo(startTime, 'seconds');
    }
  }, [startTime]);

  // useEffect(() => {
  //   const isMobile = /Mobi|Android/i.test(navigator.userAgent);

  //   if (!isMobile) return; // Код будет выполняться только на мобильных устройствах

  //   let wasPaused = false;

  //   const handleVisibilityChange = () => {
  //     if (document.visibilityState === 'visible' && wasPaused) {
  //       setUrl(''); // Сбрасываем URL
  //       setTimeout(() => setUrl(src), 100); // Перезапускаем поток
  //       wasPaused = false;
  //     } else if (document.visibilityState === 'hidden') {
  //       wasPaused = true;
  //     }
  //   };

  //   document.addEventListener('visibilitychange', handleVisibilityChange);

  //   return () => {
  //     document.removeEventListener('visibilitychange', handleVisibilityChange);
  //   };
  // }, [src]);

  return (
    <Container ref={videoRef}>
      {isLoading && <Loader size={30} />}

      <ReactPlayer
        ref={playerRef}
        url={src}
        playsInline
        playing={playing}
        volume={muted ? 0 : volume}
        muted={muted}
        onProgress={({ played, playedSeconds }: any) => {
          setPlayed(played);
          setPlayedSeconds(playedSeconds);
        }}
        controls={false}
        width="auto"
        height="auto"
        style={{
          display: 'flex',
        }}
        onDuration={(duration: any) => setDuration(duration)}
        loop={loop}
        playsinline
        fileConfig={{ attributes: { playsinline: true } }}
        onReady={handleReady}
      />

      <ControlsContainer className="constrols">
        {playControl && (
          <ButtonElem fill="clear" onClick={handlePlayPause}>
            {playing ? <PauseIcon /> : <PlayIcon />}
          </ButtonElem>
        )}

        {volumeControl && (
          <FlexElem style={{ width: 'auto' }}>
            <ButtonElem fill="clear" onClick={toggleMute}>
              {muted ? <UnmuteIcon /> : <MuteIcon />}
            </ButtonElem>
            <VolumeControl>
              <input
                id="volume"
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={volume}
                onChange={(e) => setVolume(parseFloat(e.target.value))}
                disabled={muted}
                style={{ width: '70px' }}
              />
            </VolumeControl>
          </FlexElem>
        )}

        {progressControl && (
          <FlexElem>
            <TimeDisplay>
              <span
                style={{
                  color: 'white',
                  whiteSpace: 'nowrap',
                  fontSize: '12px',
                }}
              >{`${formatTime(playedSeconds)} / ${formatTime(duration)}`}</span>
            </TimeDisplay>
            <input
              id="progress"
              type="range"
              min="0"
              max="1"
              step="0.01"
              value={played}
              style={{ width: '100%' }}
              onChange={(e) => {
                const newValue = parseFloat(e.target.value);
                setPlayed(newValue);
                playerRef &&
                  playerRef.current &&
                  playerRef.current.seekTo(newValue);
              }}
            />
          </FlexElem>
        )}

        {fullScreenControl && (
          <ButtonElem fill="clear" onClick={toggleFullscreen}>
            {isFullscreen ? <ExitFullScreenIcon /> : <EnterFullScreenIcon />}
          </ButtonElem>
        )}
      </ControlsContainer>
    </Container>
  );
};

const Loader = styled(LoaderIconElem)`
  position: absolute;
  top: 50%;
  left: 50%;
`;

const ControlsContainer = styled(FlexElem)`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 20px;
  justify-content: space-between;
  background: #00000030;
`;

const VolumeControl = styled.div`
  height: auto;
  display: flex;
  align-items: center;
  @media (max-width: 600px) {
    display: none;
  }
`;

const TimeDisplay = styled.span`
  @media (max-width: 600px) {
    display: none;
  }
`;

const Container = styled.div`
  background-color: #000;
  position: relative;
  height: 0;
  padding-top: 56.25%; /* Соотношение сторон 16:9 */
  overflow: hidden;

  .constrols {
    input {
      cursor: pointer;
    }
    svg {
      color: white;

      path {
        stroke: white;
        fill: white;
      }
      polygon {
        stroke: white;
        fill: white;
      }
    }
  }

  /* > div:first-child {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  } */

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
`;
