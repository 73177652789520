import { i18n } from '../../lib/lang';
import { convertTimeWithTimeZone } from '../../lib/lang/service';
import { COLOR_TYPE } from '../../theme/color';
import { PERSON_ROLE } from '../person/constant';
import { USER_ROLE } from '../user/constant';
import { MESSAGE_ITEM_DATA, MESSAGE_ITEM_DATA_RAW } from './constant';

const getReplyMessageName = (
  message: MESSAGE_ITEM_DATA_RAW,
): { replyName: string; replyNameColor?: COLOR_TYPE } => {
  const { name: replyName, nameColor: replyNameColor } =
    getMessageName(message);
  return { replyName, replyNameColor };
};

export const getMessageName = (
  message: MESSAGE_ITEM_DATA_RAW,
): { name: string; nameColor?: COLOR_TYPE } => {
  if (message.viewer) {
    return {
      name: `${message.viewer.name}`,
    };
  } else if (
    message?.user?.role == USER_ROLE.MODER ||
    message?.user?.role == USER_ROLE.ADMIN ||
    message?.person?.role == PERSON_ROLE.MODER
  ) {
    return {
      name: i18n.t('USER.ROLE.MODER'),
      nameColor: 'error',
    };
  } else if (
    message?.user?.role == USER_ROLE.SPEAKER ||
    message?.person?.role == PERSON_ROLE.SPEAKER
  ) {
    return {
      name: i18n.t('USER.ROLE.SPEAKER'),
      nameColor: 'active',
    };
  } else {
    return {
      name: `${message?.person?.name}`,
    };
  }
};

export const convertMessageToSelect = (arr: MESSAGE_ITEM_DATA_RAW[]) => {
  const newArray: any = [];

  if (arr) {
    arr?.map((message: MESSAGE_ITEM_DATA_RAW) => {
      newArray.push({
        value: message.id,
        content: message.content,
        title: `${
          message?.person?.name || message?.viewer?.name
        }, ${convertTimeWithTimeZone(message.createDate)}`,
      });
    });
  }
  return newArray;
};

const checkMessage = (
  message: MESSAGE_ITEM_DATA_RAW,
  sessionIsAuto?: boolean,
  viewerId?: string,
): boolean => {
  if (sessionIsAuto) {
    if (message.viewer?.id === viewerId) return true;

    if (message.user && !message.replyTo) return true;

    if (message.replyTo?.viewer?.id === viewerId) return true;

    if (message.person) return true;

    return false;
  } else {
    if (!message.visible && message.viewer?.id === viewerId) {
      return true;
    }
    if (!message.visible) {
      return false;
    }

    return true;
  }
};

function formatPhoneNumber(phoneNumber: string | undefined): string {
  if (phoneNumber) {
    // Убираем пробелы и разделяем строку на части
    const part1 = phoneNumber.slice(0, 4); // +380
    const part2 = phoneNumber.slice(4, 7); // 675
    const part3 = phoneNumber.slice(7, 10); // 658
    const maskedPart = '***'; // Маскированная часть

    return `${part1} ${part2} ${part3} ${maskedPart}`;
  } else {
    return '';
  }
}

const covertLocation = (message: MESSAGE_ITEM_DATA_RAW) => {
  const [country, all, city] = message.viewer?.location[0]?.split(',') || [
    'Null',
    'Null',
    'Null',
  ];

  return (
    `${city}, ${country}` ||
    `${message.person?.city}, ${message.person?.country}`
  );
};

export const convertMessageWithCheck = (
  message: MESSAGE_ITEM_DATA_RAW,
  sessionIsAuto?: boolean,
  viewerId?: string,
): MESSAGE_ITEM_DATA | undefined => {
  if (!checkMessage(message, sessionIsAuto, viewerId)) {
    return undefined;
  }

  return convertMessage(message);
};

export const convertMessage = (
  message: MESSAGE_ITEM_DATA_RAW,
): MESSAGE_ITEM_DATA => {
  return {
    ...message,
    createDate: convertTimeWithTimeZone(message.createDate),
    phoneNumber: formatPhoneNumber(
      message.viewer?.phone || message.person?.phone,
    ),
    photo:
      message.viewer?.photo?.url ||
      message.person?.photo?.url ||
      message.person?.photoUrl,
    location: covertLocation(message),
    ...getMessageName(message),
    ...(message.replyTo && getReplyMessageName(message.replyTo)),
  };
};

export const convertMessageListWithCheck = (
  messageList: MESSAGE_ITEM_DATA_RAW[],
  sessionIsAuto?: boolean,
  viewerId?: string,
): MESSAGE_ITEM_DATA[] => {
  return messageList
    .map((message: MESSAGE_ITEM_DATA_RAW) =>
      convertMessageWithCheck(message, sessionIsAuto, viewerId),
    )
    .filter((message): message is MESSAGE_ITEM_DATA => !!message);
};

export const convertMessageList = (
  messageList: MESSAGE_ITEM_DATA_RAW[],
): MESSAGE_ITEM_DATA[] => {
  return messageList.map((message: MESSAGE_ITEM_DATA_RAW) =>
    convertMessage(message),
  );
};
