import React from 'react';
import {
  EVENT_ITEM_DATA_RAW,
  QUESTION_TYPE,
  REACTION_RAW_TYPE,
  RESERVATION_CREATED_TYPE,
  SURVEY_TYPE,
} from '../../data/event/constant';
import styled from 'styled-components';
import { TextElem } from '../../common/text';
import { Button } from '../event-item-create/frame/button';
import { useHistory } from 'react-router';
import EmojiRow from './frame/reaction';
import { QuestionEvent } from './frame/Question';
import { ReservationCreatedEvent } from './frame/ReservationCreated';
import { ContentContainerElem } from '../../common/content-container';
import { GridElem } from '../../common/grid';
import { SessionItemRoomReservationContainer } from '../session-item-room-reservation';
import { WEBINAR_ITEM_DATA_RAW } from '../../data/webinar/constant';
import { Survey } from './frame/Survey';
import { RESERVATION_SOURCE } from '../../data/analytic/constant';

export const Component: React.FC<{
  componentsData: {
    banner?: EVENT_ITEM_DATA_RAW;
    button?: EVENT_ITEM_DATA_RAW;
    reaction?: REACTION_RAW_TYPE;
    question?: QUESTION_TYPE;
  };
  reservation?: EVENT_ITEM_DATA_RAW;
  webinarData?: WEBINAR_ITEM_DATA_RAW;
  reservationCreated?: RESERVATION_CREATED_TYPE;
  reservationButtonClick?: Function;
  sessionId: string;

  sendReaction: (emoji: string) => void;
  survey: SURVEY_TYPE;
  sendAnswer: (answer: number) => void;
  reactionActive: boolean;
}> = ({
  componentsData,
  sendReaction,
  reservationCreated,
  sessionId,
  reservation,
  webinarData,
  reservationButtonClick,
  survey,
  sendAnswer,
  reactionActive,
}) => {
  const handleClickButton = () => {
    if (componentsData.button?.link) {
      window.open(componentsData.button?.link, '_blank');
    }
  };

  const handleClickBanner = () => {
    if (componentsData.banner?.link) {
      window.open(componentsData.banner?.link, '_blank');
    }
  };

  const components = {
    banner: (item: EVENT_ITEM_DATA_RAW) => (
      <Image
        src={item?.file?.url || item.fileUrl}
        onClick={handleClickBanner}
      />
    ),
    button: (item: EVENT_ITEM_DATA_RAW) => (
      <ButtonStyled
        textColor={item.textColor}
        color={item.buttonColor}
        onClick={handleClickButton}
      >
        <TextElem tid={item.buttonText} type={'semi-bold'} />
      </ButtonStyled>
    ),
    reaction: (item: REACTION_RAW_TYPE) => (
      <EmojiRow
        data={item}
        sendReaction={sendReaction}
        reactionActive={reactionActive}
      />
    ),
    question: (item: QUESTION_TYPE) => <QuestionEvent data={item} />,
  };

  // Объект, где ключи — типы компонентов, а значения — рендер функции
  const componentList = Object.keys(componentsData)
    .filter((key) => componentsData[key as keyof typeof componentsData])
    .map((key) => {
      // Приводим к правильному типу для каждого компонента
      const dataItem = componentsData[key as keyof typeof componentsData]!;

      switch (key) {
        case 'banner':
        case 'button':
          return components[key](dataItem as EVENT_ITEM_DATA_RAW);
        case 'reaction':
          return components[key](dataItem as REACTION_RAW_TYPE);
        case 'question':
          return components[key](dataItem as QUESTION_TYPE);
        default:
          return null;
      }
    })
    .filter((component) => component !== null);

  const groupedComponents = [];
  for (let i = 0; i < componentList.length; i += 2) {
    groupedComponents.push(componentList.slice(i, i + 2));
  }

  return (
    <GridElem>
      {(reservation || reservationCreated) && (
        <ContentReservation>
          {reservation && (
            <SessionItemRoomReservationContainer
              sessionId={sessionId}
              modal
              webinarData={webinarData}
              event={reservation}
              reservationButtonClick={reservationButtonClick}
              source={RESERVATION_SOURCE.SESSION}
            />
          )}
          {reservationCreated && (
            <ReservationCreatedEvent data={reservationCreated} />
          )}
        </ContentReservation>
      )}
      {survey && <Survey data={survey} sendAnswer={sendAnswer} />}

      {groupedComponents.map((group, index) => (
        <ContentContainer key={index}>
          {group.map((component, idx) => (
            <>{component}</>
          ))}
        </ContentContainer>
      ))}
    </GridElem>
  );
};

const ContentContainer = styled(ContentContainerElem)`
  display: flex;
  padding: 16px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-items: center;

  @media screen and (width<=600px) {
    padding: 12px;
  }
  @media screen and (width<=600px) {
    display: grid;
    gap: 12px;

    justify-content: stretch;
    :first-child {
      padding: 8px;
    }
  }
`;

const ContentReservation = styled(ContentContainer)`
  @media screen and (width<=1450px) {
    display: grid;
  }
  @media (max-width: 600px) {
    gap: 12px;
  }
`;

const Image = styled.img`
  cursor: pointer;
  border-radius: 10px;
  height: 120px;
  @media (max-width: 600px) {
    height: 60px;
  }
`;

const ButtonStyled = styled(Button)`
  padding: 0 24px;

  width: 100%;
  @media (max-width: 600px) {
    height: 36px;
    span {
      font-size: 13px;
    }
  }
`;
