import React from 'react';

import {
  ACTION_ERROR_INTER,
  FORM_VALUE_ENUM,
  FORM_VALUE_INTER,
  FORM_VALUE_TYPE,
} from './constant';

import { Component } from './component';
import { email, required } from '../../lib/validation/service';
import { FormikValues, useFormik } from 'formik';
import { validation } from '../../lib/validation';
import { useMutation } from 'react-query';
import { action as fetch } from './action';
import { SELECT_OPTION_ITEM_DATA } from '../../common/select';
import { useHistory } from 'react-router';
import { SESSION_ITEM_DATA_RAW } from '../../data/session/constant';
import { SESSION_VIEWER_PAGE_PATH_DYNAMIC } from '../../page/session-viewer';

export const Container: React.FC<{
  id: string;
  webinarId: string;
  query: {
    person?: boolean;
    event?: boolean;
  };
}> = ({ id, query, webinarId }) => {
  const history = useHistory();
  const onSuccess = (d: any, values: any) => {
    const data: SESSION_ITEM_DATA_RAW = d as unknown as SESSION_ITEM_DATA_RAW;

    history.push(SESSION_VIEWER_PAGE_PATH_DYNAMIC(data.id));
  };

  const action = useMutation((values) => fetch(values, id), { onSuccess });

  const config = {
    [FORM_VALUE_ENUM.SESSION]: [required],
    [FORM_VALUE_ENUM.PERSON]: [],
    [FORM_VALUE_ENUM.EVENT]: [],
  };

  const validate = (values: FormikValues) => validation(values, config);

  const initialValues = Object.keys(query).length
    ? {
        [FORM_VALUE_ENUM.PERSON]: query?.person ? true : false,
        [FORM_VALUE_ENUM.EVENT]: query?.event ? true : false,
        [FORM_VALUE_ENUM.SESSION]: '',
      }
    : {
        [FORM_VALUE_ENUM.PERSON]: false,
        [FORM_VALUE_ENUM.EVENT]: false,
        [FORM_VALUE_ENUM.SESSION]: '',
      };

  const formik: FormikValues = useFormik({
    initialValues,
    validate,
    onSubmit: (values: FORM_VALUE_INTER) => {
      return action.mutate(values);
    },
  });

  const isFieldError = (name: FORM_VALUE_TYPE): boolean => {
    return formik.errors[name] && formik.touched[name] ? true : false;
  };

  const getFieldError = (name: FORM_VALUE_TYPE): string | undefined =>
    isFieldError(name) ? formik.errors[name] : undefined;

  const isSubmitDisabled = () => {
    if (!formik.isValid) {
      return true;
    }
    if (!formik.dirty) {
      return true;
    }

    if (action.isLoading) {
      return true;
    }

    if (action.isSuccess) {
      return true;
    }
    if (
      !formik.values[FORM_VALUE_ENUM.PERSON] &&
      !formik.values[FORM_VALUE_ENUM.EVENT]
    ) {
      return true;
    }
  };

  const isLoading = () => {
    if (action.isLoading) {
      return true;
    }
  };

  const isSuccess = () => {
    if (action.isSuccess) {
      return true;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;
    if (error) {
      return error.message;
    }
  };

  const getFieldValue = (name: FORM_VALUE_TYPE) => formik.values[name];

  const setFieldValue = (name: FORM_VALUE_TYPE, e: SELECT_OPTION_ITEM_DATA) =>
    formik.setFieldValue(name, e.value);
  return (
    <Component
      isFieldError={isFieldError}
      getFieldError={getFieldError}
      isSubmitDisabled={isSubmitDisabled}
      getFieldValue={getFieldValue}
      formik={formik}
      isError={isError()}
      isLoading={isLoading()}
      isSuccess={isSuccess()}
      errorMessage={getErrorMessage()}
      setFieldValue={setFieldValue}
      webinarId={webinarId}
    />
  );
};
