import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
  SIZE_FONT_WEIGHT_DATA,
  SIZE_FONT_WEIGHT_ENUM,
} from '../../theme/size';
import { COLOR_ENUM } from '../../theme/color';
import { Spacing } from '../../theme';
import { PROPS_TYPE } from './constant';
import { TextElem } from '../text';
import { i18n } from '../../lib/lang';
import { VALUE_OPACITY_DATA, VALUE_OPACITY_ENUM } from '../../theme/value';
import arrowIcon from '../../asset/svg/common/selectArrow.svg';
import checkIcon from '../../asset/svg/common/selectCheck.svg';
import { ReactComponent as IndicatorIcon } from '../../asset/svg/common/selectIndicator.svg';
import AsyncSelect from 'react-select/async';
import { ClearIndicator, DropdownIndicator } from '../select/elem';

export const Elem: React.FC<PROPS_TYPE> = ({
  placeholder,
  onChange,
  defaultValue,
  title,
  options,
  name,
  value,
  error,
  errorMessage,
  noOptionsMessage,
  dynamic,
  onInputChange,

  noAlertContainer = false,
  customComponents = {},
  className,
  isDisabled = undefined,
  isMulti = false,
  isRtl = false,
  closeMenuOnSelect = true,
  hideSelectedOptions = false,
  ref,
  formatGroupLabel,
  maxWidth = '100%',
  style,
  inputValue,
  isClearable = false,
  ...props
}) => {
  const refSelect = useRef<any>();

  const handleChange = (e: any) => {
    if (onChange) onChange(name, e);
  };
  const noOptionsMessageFn = (inputValue: string) => {
    return !inputValue ? 'Почніть вводити' : 'Нічого не знайдено';
  };

  return (
    <Container className={className} style={style}>
      {title && (
        <TextElem tid={title} color="textPrimary" style={{ height: '14px' }} />
      )}

      <CustomSelect
        value={value}
        classNamePrefix={'Select'}
        options={options}
        placeholder={placeholder && i18n.t(placeholder)}
        onChange={handleChange}
        name={name}
        isSearchable={dynamic || false}
        noOptionsMessage={({ inputValue }) =>
          noOptionsMessage ? noOptionsMessage : noOptionsMessageFn(inputValue)
        }
        onInputChange={onInputChange}
        menuPlacement="auto"
        defaultValue={defaultValue || 'select'}
        closeMenuOnSelect={closeMenuOnSelect}
        isDisabled={isDisabled}
        isMulti={isMulti}
        isRtl={isRtl}
        hideSelectedOptions={hideSelectedOptions}
        // controlShouldRenderValue={false}
        formatGroupLabel={formatGroupLabel}
        // menuIsOpen
        // autoFocus={true}
        ref={refSelect}
        components={{
          ...customComponents,
          DropdownIndicator,
          ClearIndicator,
        }}
        maxWidth={maxWidth}
        inputValue={inputValue}
        isClearable={isClearable}
        {...props}
      />

      {(error || errorMessage) && (
        <TextElem size="alert" color="error">
          {errorMessage}
        </TextElem>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-gap: ${Spacing(3)};
`;

const CustomSelect = styled(AsyncSelect)<{
  isDisabled: true | undefined;
  maxWidth: string;
}>`
  *:has(+ &) {
    transition: all 0.2s;
  }

  .Select__clear-indicator {
    padding: 0;
  }

  display: grid;

  .Select__control {
    overflow: hidden;
  }

  :hover {
    *:has(+ &) {
      color: ${({ theme }) => theme[COLOR_ENUM.TEXT_FOURTH]} !important;
    }
  }

  .Select__multi-value {
    background-color: transparent;
  }

  .Select__placeholder {
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PLACEHOLDER]};
  }

  .Select__menu-notice--no-options {
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PLACEHOLDER]};

    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.INPUT]}px;
    @media screen and (width<=600px) {
      font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.MOBILE_DEFAULT]}px;
    }
    font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.REGULAR]};
  }

  .Select__multi-value:not(:nth-last-child(2)) ::after {
    background-color: transparent;

    /* content: ','; */
  }
  #text {
    min-width: 100% !important;
  }

  .Select__multi-value__remove {
    display: none;
  }

  .Select__value-container--is-multi {
    ${({ maxWidth }) => {
      if (maxWidth) {
        return css`
          max-width: calc(${maxWidth} - 40px);
        `;
      }
    }}

    font-size:14px;
    @media screen and (width<=600px) {
      font-size: ${({ theme }) =>
        theme[SIZE_FONT_ENUM.MOBILE_DEFAULT]}px !important;
    }
    font-weight: 400;
  }

  & .Select__control {
    border-radius: ${SIZE_BORDER_RADIUS_DATA[
      SIZE_BORDER_RADIUS_ENUM.DEFAULT
    ]}px;
    min-height: 46px;
    max-height: 46px;
    cursor: pointer;
    box-sizing: border-box;

    padding: ${Spacing(0)} ${Spacing(4.5)} ${Spacing(0)} ${Spacing(3)};
    width: 100%;

    box-shadow: none;

    border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER_DEFAULT]};

    background: ${({ theme }) => theme[COLOR_ENUM.INPUT]};

    &:hover {
      border-color: ${({ theme }) => theme[COLOR_ENUM.BORDER_HOVER]};
    }
  }
  & .Select__control--is-focused {
    background: ${({ theme }) => theme[COLOR_ENUM.INPUT_ACTIVE]};
  }
  & .Select__control--menu-is-open {
    *:has(+ &) {
      color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]} !important;
    }
    &:hover {
      border-color: ${({ theme }) => theme[COLOR_ENUM.BORDER_DEFAULT]};
    }
    & .Select__indicator.Select__dropdown-indicator {
      transform: rotate(180deg);
    }

    border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px
      ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px 0px 0px;
    border-bottom: 1px solid transparent;
  }
  & .Select__value-container {
    /* height: 1em; */
    padding: 0;
    height: 21px;
  }

  & .Select__input-container {
    margin: 0;
    padding: 0;
    font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.REGULAR]};
    color: ${({ theme }) => theme[COLOR_ENUM.SELECT_TEXT_ACTIVE]} !important;
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.INPUT]}px;
    @media screen and (width<=600px) {
      font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.MOBILE_DEFAULT]}px;
    }
  }
  & .Select__single-value {
    color: ${({ theme }) => theme[COLOR_ENUM.SELECT_TEXT_DEFAULT]};

    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.INPUT]}px;
    @media screen and (width<=600px) {
      font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.MOBILE_DEFAULT]}px;
    }
  }
  & .Select__placeholder {
    margin: 0;
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PLACEHOLDER]};
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.INPUT]}px;
    @media screen and (width<=600px) {
      font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.MOBILE_DEFAULT]}px;
    }
    font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.REGULAR]};
  }
  & .Select__indicator-separator {
    display: none;
  }
  & .Select__indicator.Select__dropdown-indicator {
    /* width: 17px; */
    color: #000;
    padding: 0;
    transition: all 0.2s;
    margin-left: ${Spacing(2)};
  }
  & .Select__menu {
    border-radius: 0px 0px
      ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px
      ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;
    margin: 0;

    background-color: ${({ theme }) => theme[COLOR_ENUM.INPUT_ACTIVE]};
    border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};
    box-shadow: none;
    z-index: 10001;
    top: 44px;
  }

  & .Select__menu-list {
    padding: 0;

    border-radius: 0px 0px
      ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px
      ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;
    background-color: ${({ theme }) => theme[COLOR_ENUM.INPUT_ACTIVE]};
    & :last-child {
      border-color: transparent;
    }
  }
  & .Select__menu-notice {
    text-align: left;
  }

  & .Select__option {
    cursor: pointer;
    position: relative;
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_FOURTH]};
    border-bottom: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.INPUT]}px;
    @media screen and (width<=600px) {
      font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.MOBILE_DEFAULT]}px;
    }
    padding: 0 ${Spacing(4)};
    height: 47px;
    align-items: center;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
    line-height: 47px;
  }
  & .Select__option:hover {
    background-color: ${({ theme }) => theme[COLOR_ENUM.BORDER]};
  }
  & .Select__option--is-selected {
    color: ${({ theme }) => theme[COLOR_ENUM.DEFAULT]};
    background-color: transparent;
    position: relative;
  }

  & .Select__menu-list--is-multi > .Select__option {
    padding-right: 32px;
  }

  & .Select__menu-list--is-multi > .Select__option::after {
    content: '';
    width: 16px !important;
    height: 16px !important;
    background: url(${checkIcon});
    display: block;
    position: absolute;
    right: 16px;
    top: 15px;
  }

  & .Select__value-container--is-multi {
    color: ${({ theme }) => theme[COLOR_ENUM.SELECT_TEXT_DEFAULT]};

    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.INPUT]}px;
    @media screen and (width<=600px) {
      font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.MOBILE_DEFAULT]}px;
    }
  }

  & .Select__menu-list--is-multi > .Select__option--is-selected::after {
    background: url(${arrowIcon});
  }

  & .Select__option--is-focused {
    background-color: transparent;
  }

  ${({ isDisabled }) => {
    if (isDisabled) {
      return css`
        & .Select__control {
          opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.DEFAULT]};
        }
      `;
    }
  }}
`;
