const forbiddenWords = ['слово', 'плохое', 'запрещенное'];

const replaceSimilarCharacters = (text: string): string => {
  const replacements: Record<string, string> = {
    а: 'a', // кириллическая 'а' на латинскую 'a'
    е: 'e', // кириллическая 'е' на латинскую 'e'
    о: 'o', // кириллическая 'о' на латинскую 'o'
    с: 'c', // кириллическая 'с' на латинскую 'c'
    р: 'p', // кириллическая 'р' на латинскую 'p'
    у: 'y', // кириллическая 'у' на латинскую 'y'
    к: 'k', // кириллическая 'к' на латинскую 'k'
    // Добавьте другие при необходимости
  };

  return text
    .split('')
    .map((char) => replacements[char] || char)
    .join('');
};

const normalizeText = (text: string): string => {
  return replaceSimilarCharacters(
    text.toLowerCase().replace(/[\s.,?!@#$%^&*()_+\-={}[\]|:;"'<>\n\r]/g, ''), // Удаление пробелов и знаков препинания
  );
};

export const containsForbiddenWords = (
  message: string,
  forbiddenWords: string[],
): boolean => {
  const normalizedMessage = normalizeText(message);
  return forbiddenWords.some((word) =>
    normalizedMessage.includes(normalizeText(word)),
  );
};
