import { useEffect } from 'react';
import { useSocket } from '../../data/socket/provider';

import {
  MESSAGE_ITEM_DATA,
  MESSAGE_ITEM_DATA_RAW,
} from '../../data/message/constant';
import { BASE_URL } from '../../lib/http/constant';
import { useSelector } from '../../lib/store';
import { AUTH_MODULE_NAME } from '../../data/auth';
import { STORE_INTER } from '../../data/auth/constant';
import { HttpRequest } from '../../lib/http';
import { convertMessage, convertMessageList } from '../../data/message/convert';

interface useRoomSocketProps {
  sessionId: string;
  setChatMessage: React.Dispatch<React.SetStateAction<MESSAGE_ITEM_DATA[]>>;
  setSessionStart: React.Dispatch<React.SetStateAction<boolean>>;
  setSessionEnd: React.Dispatch<React.SetStateAction<boolean>>;
  setChatActive: React.Dispatch<React.SetStateAction<boolean>>;
  setPreModerationActive: React.Dispatch<React.SetStateAction<boolean>>;
}

const useRoomSocket = ({
  sessionId,
  setChatMessage,
  setSessionStart,
  setChatActive,
  setPreModerationActive,
}: useRoomSocketProps) => {
  const { socket, initializeSocket, sendMessage, changeChatActive } =
    useSocket();

  const state: STORE_INTER = useSelector((s) => s[AUTH_MODULE_NAME]);

  useEffect(() => {
    initializeSocket(BASE_URL as string, {
      auth: {
        token:
          state?.user?.token ||
          (
            HttpRequest.defaults.headers.common['Authorization'] as string
          )?.replace('Bearer ', ''),
        sessionId,
      },
    });
  }, []);

  useEffect(() => {
    if (socket) {
      console.log('if (socket) {');
      socket.on('sessionMessagesModer', (messages: MESSAGE_ITEM_DATA_RAW[]) => {
        console.log('sessionMessagesModer', messages);
        setChatMessage(convertMessageList(messages));
      });

      socket.on('chatModer', (message: MESSAGE_ITEM_DATA_RAW) => {
        console.log('chatModer', message);
        setChatMessage((previousMessages: MESSAGE_ITEM_DATA[]) => {
          return [...previousMessages, convertMessage(message)];
        });
      });

      socket.on('approveMessage', (message: MESSAGE_ITEM_DATA_RAW) => {
        console.log('approveMessage', message);
        setChatMessage((previousMessages: MESSAGE_ITEM_DATA[]) => {
          return previousMessages.map((item) => {
            if (item.id === message.id) {
              return convertMessage(message);
            }
            return item;
          });
        });
      });

      socket.on('deleteMessageList', (idsToRemove: string[]) => {
        setChatMessage((previousMessages: MESSAGE_ITEM_DATA[]) => {
          return previousMessages.filter(
            (message) => !idsToRemove?.includes(message.id),
          );
        });
      });

      socket.on('deleteMessage', (messageId: string) => {
        setChatMessage((previousMessages: MESSAGE_ITEM_DATA[]) => {
          return previousMessages.filter((msg) => msg.id !== messageId);
        });
      });

      socket.on('sessionStart', () => {
        console.log('sessionStart');
        setSessionStart(true);
      });

      socket.on('sessionEnd', () => {
        console.log('sessionEnd');
        setSessionStart(false);
      });

      socket.on('chatActivated', () => {
        console.log('chatActivated');
        setChatActive(true);
      });

      socket.on('chatInactive', () => {
        console.log('chatInactive');
        setChatActive(false);
      });

      socket.on('preModerationActivated', () => {
        console.log('preModerationActivated');
        setPreModerationActive(true);
      });

      socket.on('preModerationInactive', () => {
        console.log('preModerationInactive');
        setPreModerationActive(false);
      });
      const handleBeforeUnload = () => {
        socket.disconnect();
      };

      window.addEventListener('beforeunload', handleBeforeUnload);

      return () => {
        handleBeforeUnload();
      };
    }
  }, [socket]);
  console.log(socket, socket?.id);
  return {
    sendMessage,
    changeChatActive,
  };
};

export default useRoomSocket;
