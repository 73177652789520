import styled from 'styled-components';
import { TextElem } from '../../../common/text';
import { GridElem } from '../../../common/grid';
import { COLOR_ENUM } from '../../../theme/color';
import { useState } from 'react';
import { timeToSeconds } from '../../../lib/util/dateConvert';
import { SessionItemRoomLiveVideoContainer } from '../../session-item-room-live-video';
import { VideoPlayerElem } from '../../../common/video';

export const DetailDataContainer: React.FC<{ data: any }> = ({ data }) => {
  const [webinar, setWebinar] = useState<any>(null);

  const handleClick = (item: any) => {
    setWebinar(item); // Обновляем состояние, и видео начнется с правильного времени
  };

  return (
    <GridElem>
      {webinar ? (
        <>
          {webinar?.sessionAuto && (
            <VideoPlayerElem
              src={webinar.videoUrl}
              autoPlay={true}
              muted={false}
              startTime={timeToSeconds(data.X)}
            />
          )}
          {!webinar?.sessionAuto && (
            <SessionItemRoomLiveVideoContainer
              src={`${webinar.youtubeUrl}&start=${timeToSeconds(data.X)}`}
            />
          )}
        </>
      ) : (
        <>
          {data.modalData.map((item: any) => (
            <ContentContainer
              spacing={2}
              onClick={() => handleClick(item)}
              key={item.id}
            >
              <TextElem
                type="medium"
                size="mobileDefault"
                color="textSecondary"
              >
                {item.name}
              </TextElem>
              <TextElem size="small" color="textFourth">
                {item.webinarName}
              </TextElem>
              <TextElem size="small" color="textPrimary">
                {item.time}
              </TextElem>
            </ContentContainer>
          ))}
        </>
      )}
    </GridElem>
  );
};

const ContentContainer = styled(GridElem)`
  padding: 12px;
  border-radius: 8px;
  cursor: pointer;
  background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_THIRD]};
`;
